<template>
  <main class="content home">
    <div class="inner content-grid">
      <div class="left">
        <page-heading
          heading="Request Ad-Hoc Collection"
          icon="order-cash-for-delivery-o.png"
          :para="success ? '' : 'Please fill out the fields below to request your order.'"
        ></page-heading>
        <div v-if="!success">
          <info-panel
            content="To access each of the sections, please enter your name in the order requested by field."
          ></info-panel>
          <div class="create-order">
            <div class="order-section active">
              <div class="order-section-header">Order requested by</div>
              <div class="order-section-body">
                <div class="order-section-content">
                  <input
                    v-model="order_reference"
                    aria-label="Your name"
                    class="txt full"
                    type="text"
                    placeholder="Please enter your name (required)"
                    maxlength="100"
                  />
                </div>
              </div>
            </div>

            <div :class="{ disabled: !order_reference && !$route.params.id }">
              <div id="section3" class="order-section" @focusin="toggleOpen('section3')">
                <div class="order-section-header" @click="toggle('section3')">
                  Collection date
                  <img src="/images/icons/order-cash-tabs-arrow-n.png" alt="expand" class="expand" />
                </div>
                <div class="order-section-body" v-if="order_reference || $route.params.id">
                  <flat-pickr
                    ref="flatPickerWrapper"
                    v-model="collection_date"
                    :config="collection_date_config"
                    class="txt full date"
                  ></flat-pickr>
                </div>
              </div>

              <error-panel v-if="error_message && !split_error" :content="error_message"></error-panel>

              <div v-if="split_error" class="info issue">
                <div class="info-left" style="display: block">
                  <span class="info-circle"><img src="/images/icons/content-error-icon-white.png" alt="info" /></span>
                </div>
                <div class="info-content">
                  <div>
                    <p>Please adjust the amounts of the following denomination(s) to match a pack value:</p>
                    <ul>
                      <li v-if="note_50_error">£50</li>
                      <li v-if="note_20_error">£20</li>
                      <li v-if="note_10_error">£10</li>
                      <li v-if="note_5_error">£5</li>
                      <li v-if="coin_200_error">£2</li>
                      <li v-if="coin_100_error">£1</li>
                      <li v-if="coin_50_error">50p</li>
                      <li v-if="coin_20_error">20p</li>
                      <li v-if="coin_10_error">10p</li>
                      <li v-if="coin_5_error">5p</li>
                      <li v-if="coin_2_error">2p</li>
                      <li v-if="coin_1_error">1p</li>
                    </ul>
                    <p>
                      Alternatively, we can automatically
                      <template v-if="!roundUpExceedsCreditLimit"
                        ><a href class="btn-round" @click.prevent="roundUp">round up</a> or</template
                      >
                      <a href class="btn-round" @click.prevent="roundDown">round down</a> for you.
                    </p>
                  </div>
                </div>
              </div>

              <success-panel v-if="split_message" :content="split_message"></success-panel>

              <!-- <div v-if="order_reference" class="terms">
                <p>Please be aware that ad hoc deliveries will be charged according to your contractual
                  terms.</p>
                <p>If you choose a delivery day that does not coincide with a scheduled collection, you will be
                  charged
                  the full standard collection rate.</p>
                <p><input type="checkbox" id="chkTerms" v-model="terms_accepted" /> <label for="chkTerms">I have read
                    and understand
                    the above
                    terms.</label>
                </p>
              </div> -->

              <div class="actions" v-if="order_reference || $route.params.id">
                <button @click="clearAll" class="negative">
                  Clear All Fields <img src="/images/icons/button-icon-cancel.png" alt="Clear all fields" />
                </button>
                <button @click="submit" class="step9">
                  <template v-if="submit_loading">
                    <font-awesome-icon icon="circle-notch" class="fa-spin" aria-label="Loading" role="status" />
                  </template>
                  <template v-else> Submit <img src="/images/icons/button-icon-submit.png" alt="submit" /> </template>
                </button>
              </div>
            </div>
          </div>
        </div>
        <template v-if="success">
          <p>&nbsp;</p>
          <success-panel :content="success_order"></success-panel>
          <p class="contrast">
            <router-link to="/home">&lt; Back to Home</router-link>
          </p>
        </template>
      </div>
      <right-column :credit-limit="creditLimitToSend"></right-column>
    </div>
  </main>
</template>

<script>
import api from "@/services/api"

export default {
  data() {
    return {
      loading: true,
      success: false,
      submit_loading: false,
      error_message: "",
      success_order: "",

      order_dates: [],

      order_reference: "",
      opening: 0,
      collection_date: null,

      creditLimit: 0,
      amount: 0,

      note_50: "",
      note_20: "",
      note_10: "",
      note_5: "",
      coin_200: "",
      coin_100: "",
      coin_50: "",
      coin_20: "",
      coin_10: "",
      coin_5: "",
      coin_2: "",
      coin_1: "",

      pack_size_note_50: 2500,
      pack_size_note_20: 1000,
      pack_size_note_10: 1000,
      pack_size_note_5: 500,
      pack_size_coin_200: 500,
      pack_size_coin_100: 500,
      pack_size_coin_50: 250,
      pack_size_coin_20: 250,
      pack_size_coin_10: 100,
      pack_size_coin_5: 100,
      pack_size_coin_2: 20,
      pack_size_coin_1: 20,

      minimum_note_50: 50,
      minimum_note_20: 20,
      minimum_note_10: 10,
      minimum_note_5: 5,
      minimum_coin_200: 20,
      minimum_coin_100: 20,
      minimum_coin_50: 10,
      minimum_coin_20: 10,
      minimum_coin_10: 5,
      minimum_coin_5: 5,
      minimum_coin_2: 1,
      minimum_coin_1: 1,

      note_50_error: false,
      note_20_error: false,
      note_10_error: false,
      note_5_error: false,
      coin_200_error: false,
      coin_100_error: false,
      coin_50_error: false,
      coin_20_error: false,
      coin_10_error: false,
      coin_5_error: false,
      coin_2_error: false,
      coin_1_error: false,

      split_error: false,
      split_message: "",

      terms_accepted: false
    }
  },
  created() {
    api.request("get", "order?type=dates&order_id=" + (this.$route.params.id || "0")).then(dates => {
      this.order_dates = dates
    })

    this.loading = false
  },
  computed: {
    cutoffhour() {
      return this.$store.state.cutoffhour
    },
    cutoffminute() {
      return this.$store.state.cutoffminute
    },
    roundUpExceedsCreditLimit() {
      var test = {
        note_50: "",
        note_20: "",
        note_10: "",
        note_5: "",
        coin_200: "",
        coin_100: "",
        coin_50: "",
        coin_20: "",
        coin_10: "",
        coin_5: "",
        coin_2: "",
        coin_1: ""
      }

      if (this.note_50) test.note_50 = Math.ceil(this.note_50 / this.pack_size_note_50) * this.pack_size_note_50
      if (this.note_20) test.note_20 = Math.ceil(this.note_20 / this.pack_size_note_20) * this.pack_size_note_20
      if (this.note_10) test.note_10 = Math.ceil(this.note_10 / this.pack_size_note_10) * this.pack_size_note_10
      if (this.note_5) test.note_5 = Math.ceil(this.note_5 / this.pack_size_note_5) * this.pack_size_note_5
      if (this.coin_200) test.coin_200 = Math.ceil(this.coin_200 / this.pack_size_coin_200) * this.pack_size_coin_200
      if (this.coin_100) test.coin_100 = Math.ceil(this.coin_100 / this.pack_size_coin_100) * this.pack_size_coin_100
      if (this.coin_50) test.coin_50 = Math.ceil(this.coin_50 / this.pack_size_coin_50) * this.pack_size_coin_50
      if (this.coin_20) test.coin_20 = Math.ceil(this.coin_20 / this.pack_size_coin_20) * this.pack_size_coin_20
      if (this.coin_10) test.coin_10 = Math.ceil(this.coin_10 / this.pack_size_coin_10) * this.pack_size_coin_10
      if (this.coin_5) test.coin_5 = Math.ceil(this.coin_5 / this.pack_size_coin_5) * this.pack_size_coin_5
      if (this.coin_2) test.coin_2 = Math.ceil(this.coin_2 / this.pack_size_coin_2) * this.pack_size_coin_2
      if (this.coin_1) test.coin_1 = Math.ceil(this.coin_1 / this.pack_size_coin_1) * this.pack_size_coin_1

      var total = 0
      if (test.note_50) total += test.note_50
      if (test.note_20) total += test.note_20
      if (test.note_10) total += test.note_10
      if (test.note_5) total += test.note_5
      if (test.coin_200) total += test.coin_200
      if (test.coin_100) total += test.coin_100
      if (test.coin_50) total += test.coin_50
      if (test.coin_20) total += test.coin_20
      if (test.coin_10) total += test.coin_10
      if (test.coin_5) total += test.coin_5
      if (test.coin_2) total += test.coin_2
      if (test.coin_1) total += test.coin_1

      return total > this.customer.credit_limit
    },
    creditLimitToSend() {
      if (this.amount) return this.creditLimit
      else return this.customer.credit_limit
    },
    customer() {
      return this.$store.state.customer
    },
    collection_date_config() {
      var disable = []
      if (this.order_dates.length) disable = disable.concat(this.order_dates.map(d => d.delivery_date))

      var c = this.customer

      var minDate = new Date()
      if (c.credit_limit) {
        minDate = this.$store.getters.minOrderDate(1)
      }

      return {
        locale: {
          firstDayOfWeek: 1,
          weekdays: {
            shorthand: ["S", "M", "T", "W", "T", "F", "S"]
          }
        },
        minDate,
        altInput: true,
        altFormat: "d/m/Y",
        dateFormat: "Y-m-d",
        disable
      }
    }
  },
  methods: {
    submit() {
      this.note_50_error = false
      this.note_20_error = false
      this.note_10_error = false
      this.note_5_error = false
      this.coin_200_error = false
      this.coin_100_error = false
      this.coin_50_error = false
      this.coin_20_error = false
      this.coin_10_error = false
      this.coin_5_error = false
      this.coin_2_error = false
      this.coin_1_error = false

      this.split_message = ""
      this.split_error = false
      this.error_message = ""
      this.submit_loading = false

      var order = {
        order_reference: this.order_reference,
        opening: this.opening,
        note_50: this.note_50,
        note_20: this.note_20,
        note_10: this.note_10,
        note_5: this.note_5,
        coin_200: this.coin_200,
        coin_100: this.coin_100,
        coin_50: this.coin_50,
        coin_20: this.coin_20,
        coin_10: this.coin_10,
        coin_5: this.coin_5,
        coin_2: this.coin_2,
        coin_1: this.coin_1,
        collection_date: this.collection_date
      }

      if (!order.order_reference) {
        this.error_message = "Please enter your name"
        return
      }

      if (!order.collection_date) {
        this.error_message = "Collection date not selected"
        return
      }

      var collection_date = new Date(order.collection_date)
      if (isNaN(collection_date.getTime())) {
        this.error_message = "Invalid date"
        return
      }

      // Collection date must be beyond customer lead time and holiday period
      if (collection_date < this.$store.getters.minOrderDate(1)) {
        this.error_message = "Collection date before lead time"
        return
      }

      this.submit_loading = true

      api
        .request("post", "order-ad-hoc-collection", { order })
        .then(() => {
          this.success_order = "Thank you, your collection has now been requested."
          this.success = true
          this.submit_loading = false
          window.scrollTo(0, 0)
        })
        .catch(err => {
          if (err.response && err.response.status == 400) {
            this.error_message = err.response.data
          } else {
            this.error_message = "There has been an error, please try again"
          }
          this.submit_loading = false
        })
    },
    clearAll() {
      this.split_message = ""
      this.split_error = false
      this.error_message = ""
      this.submit_loading = false

      this.order_reference = ""
      this.opening = ""
      this.note_50 = ""
      this.note_20 = ""
      this.note_10 = ""
      this.note_5 = ""
      this.coin_200 = ""
      this.coin_100 = ""
      this.coin_50 = ""
      this.coin_20 = ""
      this.coin_10 = ""
      this.coin_5 = ""
      this.coin_2 = ""
      this.coin_1 = ""
      this.amount = 0
      this.collection_date = ""
    },
    toggle(target) {
      var parent = document.getElementById(target)
      var panel = parent.querySelector(".order-section-body")
      if (parent.classList.contains("active")) {
        panel.style.maxHeight = null
        parent.classList.remove("active")
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px"
        parent.classList.add("active")
      }
    },
    toggleOpen(target) {
      if (this.order_reference || this.$route.params.id) {
        var parent = document.getElementById(target)
        var panel = parent.querySelector(".order-section-body")

        panel.style.maxHeight = panel.scrollHeight + "px"
        parent.classList.add("active")
      }
    }
  },
  mounted() {
    const flatPickerWrapperElement = this.$refs.flatPickerWrapper.getElem().nextElementSibling
    flatPickerWrapperElement.setAttribute("aria-label", "Collection Date")
  },
  watch: {
    order_reference: function () {
      if (!this.order_reference) {
        const totalSections = 5

        for (let i = 1; i <= totalSections; i++) {
          const parent = document.getElementById(`section${i}`)

          if (parent) {
            const panel = parent.querySelector(".order-section-body")

            if (panel) {
              panel.style.maxHeight = null
            }

            parent.classList.remove("active")
          }
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.success {
  margin: 2rem 0;
}

.terms {
  font-weight: bold;
  margin-top: 2rem;
  font-size: 2.1rem;
}

.terms p {
  margin-bottom: 1.5rem;
}

.order-section {
  transition: all 0.2s;
  margin-top: 2rem;
}

.active .order-section-body {
  max-height: none;
}

.order-section-header {
  cursor: pointer;
  background: #f3f3f3;
  padding: 1.5rem 2rem;
  font-size: 2.5rem;
  transition: all 0.2s;
}

.subheading {
  color: #ca0028;
  padding: 1.5rem 0;
  font-size: 2.5rem;
}

.active .order-section-header {
  background: #fff;
  color: #ca0028;
}

.active {
  box-shadow: 0px 0px 13px -2px #d3d3d3;
}

.order-section-body {
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s;
  border-bottom: 0rem solid transparent;
  width: 100%;
  opacity: 0;
}

.active .order-section-body {
  border-bottom: 0.5rem solid #ca0028;
  padding: 3rem 2rem;
  padding-top: 1rem;
  display: table;
  opacity: 1;
}

.btn-round {
  background: white;
  padding: 0.25rem 1rem;
  margin: 0 0.5rem;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 1px solid white;
  border-radius: 1rem;
  color: #333;
}

.info-content p {
  margin: 0.5rem 0;
}

.halved {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.expand {
  float: right;
  height: 2rem;
  position: relative;
  top: 0.5rem;
  transition: all 0.4s;
}

.active .expand {
  transform: rotate(270deg);
}

.radio {
  display: inline-block;
  margin: 0.5rem 0;
  margin-right: 3rem;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 2.8rem;
  cursor: pointer;
  line-height: 2rem;
  display: inline-block;
  font-size: 2.5rem;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1.8rem;
  height: 1.8rem;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 1.2rem;
  height: 1.2rem;
  background: #ca0028;
  position: absolute;
  top: 0.3rem;
  left: 0.3rem;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

[type="radio"]:focus-visible + label:before {
  outline: 2px solid #cb4e0b !important;
  outline-offset: 2px;
}

.txterror {
  color: #ca0028;
  border: 1px solid #ca0028;
}

.table-qty {
  float: left;
  font-weight: bold;
  margin-right: 1rem;
}

.table-qty td {
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.table-qty td:first-child {
  position: relative;
  top: 0.2rem;
}

.table-qty tr:last-child td {
  padding-bottom: 0;
}

.qty {
  width: 15rem;
}

.prefix {
  display: inline-block;
  margin-left: 1rem;
  margin-right: 0.5rem;
  opacity: 0.4;
}

.or,
.end_date {
  display: inline-block;
}

.or {
  color: #cccccc;
  text-decoration: underline;
  margin-right: 3rem;
  font-size: 2.5rem;
  font-weight: bold;
}

.order-total {
  clear: both;
  font-weight: bold;
  padding-top: 2.5rem;
}

.order-total span {
  color: #ca0028;
}

@media screen and (max-width: 950px) {
  .halved {
    grid-template-columns: 1fr;
  }

  .table-qty td:first-child {
    top: 0.1rem;
  }
}

@media screen and (max-width: 600px) {
  .qty {
    width: 9rem;
  }

  .table-qty:last-child {
    margin-right: 0;
  }

  .actions button {
    font-size: 1.8rem;
  }

  .actions button img {
    height: 1.5rem;
    margin-bottom: 0;
  }
}

.disabled {
  opacity: 0.5;
  /* Greyed out appearance */
  pointer-events: none;
  /* Disables clicking on any element inside */
}
</style>
