<template>
  <main class="content home">
    <div class="inner mobile">
      <right-column :scheduled="scheduled" :history="history" :mobile="true"></right-column>
    </div>
    <div class="inner content-grid">
      <div class="left step5 tourpad">
        <page-heading
          heading="Ad-hoc Order Management"
          icon="cash-order-management-o.png"
          para="Request cash ad-hoc"
        ></page-heading>

        <div class="blocks">
          <router-link class="block-link" to="/request-ad-hoc-delivery" v-if="adHocDeliveriesAllowed">
            <span
              ><span><img src="/images/icons/order-cash-for-delivery-o.png" alt="Request Ad-Hoc Delivery" /></span
            ></span>
            <span
              ><span>Request Ad-Hoc<br />Delivery</span></span
            >
          </router-link>
          <router-link class="block-link" to="/request-ad-hoc-collection" v-if="adHocCollectionsAllowed">
            <span
              ><span><img src="/images/icons/order-cash-for-delivery-o.png" alt="Request Ad-Hoc Collection" /></span
            ></span>
            <span
              ><span>Request Ad-Hoc<br />Collection</span></span
            >
          </router-link>
        </div>

        <Orders-Table />
      </div>
      <div class="hide-cutoff-mobile">
        <right-column :scheduled="scheduled" :history="history"></right-column>
      </div>
    </div>
  </main>
</template>

<script>
import api from "@/services/api"
import OrdersTable from "../components/OrdersTable.vue"

export default {
  components: {
    OrdersTable
  },
  data() {
    return {
      scheduled: [],
      history: []
    }
  },
  created() {
    api.request("get", "order?type=scheduled").then(res => {
      this.scheduled = res.orders
    })
    api.request("get", "order?type=history").then(res => {
      this.history = res.orders
    })
  },
  computed: {
    adHocDeliveriesAllowed() {
      if (this.$store.state.user_type == "hq-as-customer") {
        return this.$store.state.hq_user.hq_ad_hoc_deliveries
      }

      return false
    },
    adHocCollectionsAllowed() {
      if (this.$store.state.user_type == "hq-as-customer") {
        return this.$store.state.hq_user.hq_ad_hoc_collections
      }

      return false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
