<template>
  <main class="content home">
    <div class="inner content-grid">
      <div class="left step7">
        <page-heading
          heading="Order Cash for Delivery"
          icon="order-cash-for-delivery-o.png"
          :para="success ? success_order : 'Please fill out the fields below to place your order.'"
        ></page-heading>
        <div v-if="!success">
          <info-panel
            content="To access each of the sections, please enter your name in the order placed by field."
          ></info-panel>
          <div class="create-order">
            <div class="order-section active">
              <div class="order-section-header">Order placed by</div>
              <div class="order-section-body">
                <div class="order-section-content">
                  <input
                    v-model="order_reference"
                    aria-label="Your name"
                    class="txt full"
                    type="text"
                    placeholder="Please enter your name (required)"
                    maxlength="100"
                  />
                </div>
              </div>
            </div>

            <div :class="{ disabled: !order_reference && !$route.params.id }">
              <!-- <div id="section1" class="order-section" v-if="!editingStandingOrder" @focusin="toggleOpen('section1')">
                <div class="order-section-header" @click="toggle('section1')">
                  Is this an initial order for store opening?
                  <img src="/images/icons/order-cash-tabs-arrow-n.png" alt="expand" class="expand" />
                </div>
                <div class="order-section-body" v-if="order_reference || $route.params.id">
                  <div class="order-section-content radios">
                    <div class="radio">
                      <input v-model="opening" type="radio" name="radOpening" :value="1" id="radOpeningYes" />
                      <label for="radOpeningYes">Yes</label>
                    </div>
                    <div class="radio">
                      <input v-model="opening" type="radio" name="radOpening" :value="0" id="radOpeningNo" />
                      <label for="radOpeningNo">No</label>
                    </div>
                  </div>
                </div>
              </div> -->
              <div id="section2" class="order-section" @focusin="toggleOpen('section2')">
                <div class="order-section-header" @click="toggle('section2')">
                  What would you like to order?
                  <img src="/images/icons/order-cash-tabs-arrow-n.png" alt="expand" class="expand" />
                </div>
                <div class="order-section-body" v-if="order_reference || $route.params.id">
                  <template v-if="customer.note_enabled && customer.coin_enabled">
                    <table class="table-qty">
                      <tr>
                        <td>&pound;50</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="note_50"
                            aria-label="Fifty pound notes"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? 50 : pack_size_note_50"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>&pound;20</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="note_20"
                            aria-label="Twenty pound notes"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? 20 : pack_size_note_20"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>&pound;10</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="note_10"
                            aria-label="Ten pound notes"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? 10 : pack_size_note_10"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>&pound;5</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="note_5"
                            aria-label="Five pound notes"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? 5 : pack_size_note_5"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>&pound;2</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="coin_200"
                            aria-label="Two pound coins"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? minimum_coin_200 : pack_size_coin_200"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>&pound;1</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="coin_100"
                            aria-label="One pound coins"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? minimum_coin_100 : pack_size_coin_100"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                    </table>

                    <table class="table-qty">
                      <tr>
                        <td>50p</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="coin_50"
                            aria-label="Fifty pence coins"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? minimum_coin_50 : pack_size_coin_50"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>20p</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="coin_20"
                            aria-label="Twenty pence coins"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? minimum_coin_20 : pack_size_coin_20"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>10p</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="coin_10"
                            aria-label="Ten pence coins"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? minimum_coin_10 : pack_size_coin_10"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>5p</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="coin_5"
                            aria-label="Five pence coins"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? minimum_coin_5 : pack_size_coin_5"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>2p</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="coin_2"
                            aria-label="Two pence coins"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? minimum_coin_2 : pack_size_coin_2"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>1p</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="coin_1"
                            aria-label="One pence coins"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? minimum_coin_1 : pack_size_coin_1"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                    </table>
                  </template>

                  <template v-else-if="customer.note_enabled && !customer.coin_enabled">
                    <table class="table-qty">
                      <tr>
                        <td>&pound;50</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="note_50"
                            aria-label="Fifty pound notes"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? 50 : pack_size_note_50"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>&pound;20</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="note_20"
                            aria-label="Twenty pound notes"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? 20 : pack_size_note_20"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                    </table>

                    <table class="table-qty">
                      <tr>
                        <td>&pound;10</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="note_10"
                            aria-label="Ten pound notes"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? 10 : pack_size_note_10"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>&pound;5</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="note_5"
                            aria-label="Five pound notes"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? 5 : pack_size_note_5"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                    </table>
                  </template>

                  <template v-else-if="!customer.note_enabled && customer.coin_enabled">
                    <table class="table-qty">
                      <tr>
                        <td>&pound;2</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="coin_200"
                            aria-label="Two pound coins"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? minimum_coin_200 : pack_size_coin_200"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>&pound;1</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="coin_100"
                            aria-label="One pound coins"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? minimum_coin_100 : pack_size_coin_100"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>50p</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="coin_50"
                            aria-label="Fifty pence coins"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? minimum_coin_50 : pack_size_coin_50"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>20p</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="coin_20"
                            aria-label="Twenty pence coins"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? minimum_coin_20 : pack_size_coin_20"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                    </table>

                    <table class="table-qty">
                      <tr>
                        <td>10p</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="coin_10"
                            aria-label="Ten pence coins"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? minimum_coin_10 : pack_size_coin_10"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>5p</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="coin_5"
                            aria-label="Five pence coins"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? minimum_coin_5 : pack_size_coin_5"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>2p</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="coin_2"
                            aria-label="Two pence coins"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? minimum_coin_2 : pack_size_coin_2"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>1p</td>
                        <td>
                          <span class="prefix">&pound;</span>
                          <input
                            v-model.number="coin_1"
                            aria-label="One pence coins"
                            @change="amountChange"
                            class="txt qty"
                            type="number"
                            :step="customer.split_enabled ? minimum_coin_1 : pack_size_coin_1"
                            placeholder="0"
                            min="0"
                          />
                        </td>
                      </tr>
                    </table>
                  </template>

                  <div class="order-total" v-if="this.amount">
                    <span>Total:</span> {{ "&pound;" + this.amount.toFixed("2").replace(".00", "") }}
                  </div>
                </div>
              </div>
              <div id="section3" class="order-section" v-if="!editingStandingOrder" @focusin="toggleOpen('section3')">
                <div class="order-section-header" @click="toggle('section3')">
                  Delivery date
                  <img src="/images/icons/order-cash-tabs-arrow-n.png" alt="expand" class="expand" />
                </div>
                <div class="order-section-body" v-if="order_reference || $route.params.id">
                  <flat-pickr
                    ref="flatPickerWrapper"
                    v-model="delivery_date"
                    :config="delivery_date_config"
                    class="txt full date"
                  ></flat-pickr>
                </div>
              </div>
              <div
                id="section4"
                v-if="!this.$route.params.id && customer.allow_standing_orders && opening == '0'"
                @focusin="toggleOpen('section4')"
                class="order-section"
              >
                <div class="order-section-header" @click="toggle('section4')">
                  Can we help by setting this up as a standing order?
                  <img src="/images/icons/order-cash-tabs-arrow-n.png" alt="expand" class="expand" />
                </div>
                <div class="order-section-body" v-if="order_reference || $route.params.id">
                  <div class="order-section-content">
                    <div class="radios">
                      <div class="radio">
                        <input
                          v-model="standing_order"
                          type="radio"
                          name="radStandingOrder"
                          :value="true"
                          id="radStandingOrderYes"
                        />
                        <label for="radStandingOrderYes">Yes</label>
                      </div>
                      <div class="radio">
                        <input
                          v-model="standing_order"
                          type="radio"
                          name="radStandingOrder"
                          :value="false"
                          id="radStandingOrderNo"
                        />
                        <label for="radStandingOrderNo">No</label>
                      </div>
                    </div>

                    <template v-if="standing_order">
                      <div class="halved">
                        <div>
                          <div class="subheading">Frequency:</div>
                          <div class="radios">
                            <div class="radio">
                              <input
                                v-model="standing_order_frequency"
                                type="radio"
                                name="radFrequency"
                                :value="1"
                                id="radFrequencyWeekly"
                              />
                              <label for="radFrequencyWeekly">Weekly</label>
                            </div>
                            <div class="radio">
                              <input
                                v-model="standing_order_frequency"
                                type="radio"
                                name="radFrequency"
                                :value="2"
                                id="radFrequencyFortnightly"
                              />
                              <label for="radFrequencyFortnightly">Fortnightly</label>
                            </div>
                            <div class="radio">
                              <input
                                v-model="standing_order_frequency"
                                type="radio"
                                name="radFrequency"
                                :value="4"
                                id="radFrequencyFourWeekly"
                              />
                              <label for="radFrequencyFourWeekly">Four Weekly</label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="subheading">End date:</div>
                          <div class="radios">
                            <!--    <div class="radio">
                        <input
                          v-model="standing_order_end_date_indefinitely"
                          type="radio"
                          name="radIndefinitely"
                          value="indefinitely"
                          id="radIndefinitely"
                        />
                        <label for="radIndefinitely">Indefinitely</label>
                      </div>
                      <div class="or">or</div> -->
                            <div class="end_date">
                              <flat-pickr
                                v-model="standing_order_end_date"
                                :config="standing_order_config"
                                class="txt date"
                              ></flat-pickr>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div id="section5" v-if="editingStandingOrder" class="order-section" @focusin="toggleOpen('section5')">
                <div class="order-section-header" @click="toggle('section5')">
                  Standing order end date
                  <img src="/images/icons/order-cash-tabs-arrow-n.png" alt="expand" class="expand" />
                </div>
                <div class="order-section-body" v-if="order_reference || $route.params.id">
                  <div class="order-section-content">
                    <div class="radios">
                      <!--    <div class="radio">
                        <input
                          v-model="standing_order_end_date_indefinitely"
                          type="radio"
                          name="radIndefinitely"
                          value="indefinitely"
                          id="radIndefinitely"
                        />
                        <label for="radIndefinitely">Indefinitely</label>
                      </div>
                      <div class="or">or</div> -->
                      <div class="end_date">
                        <flat-pickr
                          v-model="standing_order_end_date"
                          :config="standing_order_config"
                          class="txt date"
                        ></flat-pickr>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <error-panel v-if="error_message && !split_error" :content="error_message"></error-panel>

              <div v-if="split_error" class="info issue">
                <div class="info-left" style="display: block">
                  <span class="info-circle"><img src="/images/icons/content-error-icon-white.png" alt="info" /></span>
                </div>
                <div class="info-content">
                  <div>
                    <p>Please adjust the amounts of the following denomination(s) to match a pack value:</p>
                    <ul>
                      <li v-if="note_50_error">£50</li>
                      <li v-if="note_20_error">£20</li>
                      <li v-if="note_10_error">£10</li>
                      <li v-if="note_5_error">£5</li>
                      <li v-if="coin_200_error">£2</li>
                      <li v-if="coin_100_error">£1</li>
                      <li v-if="coin_50_error">50p</li>
                      <li v-if="coin_20_error">20p</li>
                      <li v-if="coin_10_error">10p</li>
                      <li v-if="coin_5_error">5p</li>
                      <li v-if="coin_2_error">2p</li>
                      <li v-if="coin_1_error">1p</li>
                    </ul>
                    <p>
                      Alternatively, we can automatically
                      <template v-if="!roundUpExceedsCreditLimit"
                        ><a href class="btn-round" @click.prevent="roundUp">round up</a> or</template
                      >
                      <a href class="btn-round" @click.prevent="roundDown">round down</a> for you.
                    </p>
                  </div>
                </div>
              </div>

              <success-panel v-if="split_message" :content="split_message"></success-panel>

              <div class="actions" v-if="order_reference || $route.params.id">
                <button @click="clearAll" class="negative">
                  Clear All Fields <img src="/images/icons/button-icon-cancel.png" alt="Clear all fields" />
                </button>
                <button @click="submit" class="step9" :disabled="submit_loading">
                  <template v-if="submit_loading">
                    <font-awesome-icon icon="circle-notch" class="fa-spin" aria-label="Loading" role="status" />
                  </template>
                  <template v-else> Submit <img src="/images/icons/button-icon-submit.png" alt="submit" /> </template>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="info confirmed">
            <div class="info-left">
              <span class="info-circle"><img src="/images/icons/content-confirm-icon-white.png" alt="tick" /></span>
            </div>
            <div class="info-content">
              <div>
                To view all orders placed, take a look at your
                <router-link to="/cash-order-management">Scheduled Orders</router-link> and
                <router-link to="/order-history">Order History</router-link>.
              </div>
            </div>
          </div>
          <p class="contrast">
            <router-link to="/cash-order-management">&lt; Back to Cash Order Management</router-link>
          </p>
        </div>
      </div>
      <right-column :credit-limit="creditLimitToSend"></right-column>
    </div>
  </main>
</template>

<script>
import api from "@/services/api"

export default {
  data() {
    return {
      loading: true,
      success: false,
      submit_loading: false,
      error_message: "",
      success_order: "",

      order_dates: [],

      order_reference: "",
      opening: 0,
      delivery_date: null,
      standing_order: "",
      standing_order_frequency: 0,
      standing_order_end_date_indefinitely: "",
      standing_order_end_date: "",

      creditLimit: 0,
      amount: 0,

      note_50: "",
      note_20: "",
      note_10: "",
      note_5: "",
      coin_200: "",
      coin_100: "",
      coin_50: "",
      coin_20: "",
      coin_10: "",
      coin_5: "",
      coin_2: "",
      coin_1: "",

      pack_size_note_50: 2500,
      pack_size_note_20: 1000,
      pack_size_note_10: 1000,
      pack_size_note_5: 500,
      pack_size_coin_200: 500,
      pack_size_coin_100: 500,
      pack_size_coin_50: 250,
      pack_size_coin_20: 250,
      pack_size_coin_10: 100,
      pack_size_coin_5: 100,
      pack_size_coin_2: 20,
      pack_size_coin_1: 20,

      minimum_note_50: 50,
      minimum_note_20: 20,
      minimum_note_10: 10,
      minimum_note_5: 5,
      minimum_coin_200: 20,
      minimum_coin_100: 20,
      minimum_coin_50: 10,
      minimum_coin_20: 10,
      minimum_coin_10: 5,
      minimum_coin_5: 5,
      minimum_coin_2: 1,
      minimum_coin_1: 1,

      note_50_error: false,
      note_20_error: false,
      note_10_error: false,
      note_5_error: false,
      coin_200_error: false,
      coin_100_error: false,
      coin_50_error: false,
      coin_20_error: false,
      coin_10_error: false,
      coin_5_error: false,
      coin_2_error: false,
      coin_1_error: false,

      split_error: false,
      split_message: "",

      stepText: [
        "This screen allows you to go through the process of placing an order for the delivery of cash. Simply go through each of the steps and fill in the relevant details. Clicking each of the tabs will open up to show the options available.",
        "The ‘Credit Limit’ box highlights the limit on your account, as agreed with your banking partner. This will decrease accordingly as you enter a value into the ordering section.",
        "Once you have completed the details of your order, click the ‘Submit’ button at the bottom.",
        "To navigate back to any of the previous sections, click the links in the top bar.",
        "Alternatively, use the links in the sidebar to navigate to other sections of the Portal.",
        "To return to the dashboard screen, click ‘Home’ in the Main Menu."
      ]
    }
  },
  created() {
    api.request("get", "order?type=dates&order_id=" + (this.$route.params.id || "0")).then(dates => {
      this.order_dates = dates
    })

    if (this.$route.params.id) {
      var typestring = ""
      if (this.editingStandingOrder) typestring = "&edittype=standingorder"

      api.request("get", "order?id=" + this.$route.params.id + typestring).then(order => {
        this.coin_200 = order.coin_200 || ""
        this.coin_100 = order.coin_100 || ""
        this.coin_50 = order.coin_50 || ""
        this.coin_20 = order.coin_20 || ""
        this.coin_10 = order.coin_10 || ""
        this.coin_5 = order.coin_5 || ""
        this.coin_2 = order.coin_2 || ""
        this.coin_1 = order.coin_1 || ""
        this.delivery_date = order.delivery_date
        this.note_50 = order.note_50 || ""
        this.note_20 = order.note_20 || ""
        this.note_10 = order.note_10 || ""
        this.note_5 = order.note_5 || ""
        this.opening = order.opening
        this.order_reference = order.order_reference
        this.standing_order_end_date = order.end_date

        this.amountChange()

        this.loading = false
      })
    } else this.loading = false
  },
  computed: {
    cutoffhour() {
      return this.$store.state.cutoffhour
    },
    cutoffminute() {
      return this.$store.state.cutoffminute
    },
    roundUpExceedsCreditLimit() {
      var test = {
        note_50: "",
        note_20: "",
        note_10: "",
        note_5: "",
        coin_200: "",
        coin_100: "",
        coin_50: "",
        coin_20: "",
        coin_10: "",
        coin_5: "",
        coin_2: "",
        coin_1: ""
      }

      if (this.note_50) test.note_50 = Math.ceil(this.note_50 / this.pack_size_note_50) * this.pack_size_note_50
      if (this.note_20) test.note_20 = Math.ceil(this.note_20 / this.pack_size_note_20) * this.pack_size_note_20
      if (this.note_10) test.note_10 = Math.ceil(this.note_10 / this.pack_size_note_10) * this.pack_size_note_10
      if (this.note_5) test.note_5 = Math.ceil(this.note_5 / this.pack_size_note_5) * this.pack_size_note_5
      if (this.coin_200) test.coin_200 = Math.ceil(this.coin_200 / this.pack_size_coin_200) * this.pack_size_coin_200
      if (this.coin_100) test.coin_100 = Math.ceil(this.coin_100 / this.pack_size_coin_100) * this.pack_size_coin_100
      if (this.coin_50) test.coin_50 = Math.ceil(this.coin_50 / this.pack_size_coin_50) * this.pack_size_coin_50
      if (this.coin_20) test.coin_20 = Math.ceil(this.coin_20 / this.pack_size_coin_20) * this.pack_size_coin_20
      if (this.coin_10) test.coin_10 = Math.ceil(this.coin_10 / this.pack_size_coin_10) * this.pack_size_coin_10
      if (this.coin_5) test.coin_5 = Math.ceil(this.coin_5 / this.pack_size_coin_5) * this.pack_size_coin_5
      if (this.coin_2) test.coin_2 = Math.ceil(this.coin_2 / this.pack_size_coin_2) * this.pack_size_coin_2
      if (this.coin_1) test.coin_1 = Math.ceil(this.coin_1 / this.pack_size_coin_1) * this.pack_size_coin_1

      var total = 0
      if (test.note_50) total += test.note_50
      if (test.note_20) total += test.note_20
      if (test.note_10) total += test.note_10
      if (test.note_5) total += test.note_5
      if (test.coin_200) total += test.coin_200
      if (test.coin_100) total += test.coin_100
      if (test.coin_50) total += test.coin_50
      if (test.coin_20) total += test.coin_20
      if (test.coin_10) total += test.coin_10
      if (test.coin_5) total += test.coin_5
      if (test.coin_2) total += test.coin_2
      if (test.coin_1) total += test.coin_1

      return total > this.customer.credit_limit
    },
    editingStandingOrder() {
      return this.$route.name == "Edit Standing Order"
    },
    creditLimitToSend() {
      if (this.amount) return this.creditLimit
      else return this.customer.credit_limit
    },
    customer() {
      return this.$store.state.customer
    },
    non_delivery_dates() {
      return this.$store.state.non_delivery_dates || []
    },
    holiday_periods() {
      return this.$store.state.holiday_periods
    },
    delivery_date_config() {
      var disable = []
      if (this.non_delivery_dates) disable = disable.concat(this.non_delivery_dates.map(d => d.non_delivery_date))
      if (this.order_dates.length) disable = disable.concat(this.order_dates.map(d => d.delivery_date))

      var c = this.customer

      var minDate = new Date()
      if (c.credit_limit) {
        var disableDayOfWeek = function (date) {
          if (c.coin_all || c.note_all || c.split_all) return false

          switch (date.getDay()) {
            case 1:
              if (
                !(c.note_enabled && c.note_mon) &&
                !(c.coin_enabled && c.coin_mon) &&
                !(c.split_enabled && c.split_mon)
              )
                return true
              break
            case 2:
              if (
                !(c.note_enabled && c.note_tue) &&
                !(c.coin_enabled && c.coin_tue) &&
                !(c.split_enabled && c.split_tue)
              )
                return true
              break
            case 3:
              if (
                !(c.note_enabled && c.note_wed) &&
                !(c.coin_enabled && c.coin_wed) &&
                !(c.split_enabled && c.split_wed)
              )
                return true
              break
            case 4:
              if (
                !(c.note_enabled && c.note_thu) &&
                !(c.coin_enabled && c.coin_thu) &&
                !(c.split_enabled && c.split_thu)
              )
                return true
              break
            case 5:
              if (
                !(c.note_enabled && c.note_fri) &&
                !(c.coin_enabled && c.coin_fri) &&
                !(c.split_enabled && c.split_fri)
              )
                return true
              break
            case 6:
              if (
                !(c.note_enabled && c.note_sat) &&
                !(c.coin_enabled && c.coin_sat) &&
                !(c.split_enabled && c.split_sat)
              )
                return true
              break
            case 0:
              if (
                !(c.note_enabled && c.note_sun) &&
                !(c.coin_enabled && c.coin_sun) &&
                !(c.split_enabled && c.split_sun)
              )
                return true
              break
          }
        }
        disable = disable.concat(disableDayOfWeek)

        minDate = this.$store.getters.minOrderDate(0)
      }

      return {
        locale: {
          firstDayOfWeek: 1,
          weekdays: {
            shorthand: ["S", "M", "T", "W", "T", "F", "S"]
          }
        },
        minDate,
        altInput: true,
        altFormat: "d/m/Y",
        dateFormat: "Y-m-d",
        disable
      }
    },
    standing_order_config() {
      var minDate = new Date()

      if (this.editingStandingOrder) {
        minDate = new Date(this.delivery_date)
      } else if (this.delivery_date) {
        minDate = new Date(this.delivery_date)
        minDate.setDate(minDate.getDate() + (this.standing_order_frequency || 1) * 7)
      }
      minDate = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate())

      return {
        locale: {
          firstDayOfWeek: 1,
          weekdays: {
            shorthand: ["S", "M", "T", "W", "T", "F", "S"]
          }
        },
        minDate,
        altInput: true,
        altFormat: "d/m/Y",
        dateFormat: "Y-m-d",
        disableMobile: "true",
        onReady: (a, b, fp) => {
          fp.altInput.setAttribute("aria-label", "Delivery Date")
        }
      }
    }
  },
  methods: {
    roundUp() {
      this.error_message = ""
      this.split_error = false

      if (this.note_50) this.note_50 = Math.ceil(this.note_50 / this.pack_size_note_50) * this.pack_size_note_50
      if (this.note_20) this.note_20 = Math.ceil(this.note_20 / this.pack_size_note_20) * this.pack_size_note_20
      if (this.note_10) this.note_10 = Math.ceil(this.note_10 / this.pack_size_note_10) * this.pack_size_note_10
      if (this.note_5) this.note_5 = Math.ceil(this.note_5 / this.pack_size_note_5) * this.pack_size_note_5
      if (this.coin_200) this.coin_200 = Math.ceil(this.coin_200 / this.pack_size_coin_200) * this.pack_size_coin_200
      if (this.coin_100) this.coin_100 = Math.ceil(this.coin_100 / this.pack_size_coin_100) * this.pack_size_coin_100
      if (this.coin_50) this.coin_50 = Math.ceil(this.coin_50 / this.pack_size_coin_50) * this.pack_size_coin_50
      if (this.coin_20) this.coin_20 = Math.ceil(this.coin_20 / this.pack_size_coin_20) * this.pack_size_coin_20
      if (this.coin_10) this.coin_10 = Math.ceil(this.coin_10 / this.pack_size_coin_10) * this.pack_size_coin_10
      if (this.coin_5) this.coin_5 = Math.ceil(this.coin_5 / this.pack_size_coin_5) * this.pack_size_coin_5
      if (this.coin_2) this.coin_2 = Math.ceil(this.coin_2 / this.pack_size_coin_2) * this.pack_size_coin_2
      if (this.coin_1) this.coin_1 = Math.ceil(this.coin_1 / this.pack_size_coin_1) * this.pack_size_coin_1
      this.amountChange()

      this.split_message = "Ok that's done! Please check the new values before proceeding."
    },
    roundDown() {
      this.error_message = ""
      this.split_error = false

      if (this.note_50) this.note_50 = Math.floor(this.note_50 / this.pack_size_note_50) * this.pack_size_note_50
      if (this.note_20) this.note_20 = Math.floor(this.note_20 / this.pack_size_note_20) * this.pack_size_note_20
      if (this.note_10) this.note_10 = Math.floor(this.note_10 / this.pack_size_note_10) * this.pack_size_note_10
      if (this.note_5) this.note_5 = Math.floor(this.note_5 / this.pack_size_note_5) * this.pack_size_note_5
      if (this.coin_200) this.coin_200 = Math.floor(this.coin_200 / this.pack_size_coin_200) * this.pack_size_coin_200
      if (this.coin_100) this.coin_100 = Math.floor(this.coin_100 / this.pack_size_coin_100) * this.pack_size_coin_100
      if (this.coin_50) this.coin_50 = Math.floor(this.coin_50 / this.pack_size_coin_50) * this.pack_size_coin_50
      if (this.coin_20) this.coin_20 = Math.floor(this.coin_20 / this.pack_size_coin_20) * this.pack_size_coin_20
      if (this.coin_10) this.coin_10 = Math.floor(this.coin_10 / this.pack_size_coin_10) * this.pack_size_coin_10
      if (this.coin_5) this.coin_5 = Math.floor(this.coin_5 / this.pack_size_coin_5) * this.pack_size_coin_5
      if (this.coin_2) this.coin_2 = Math.floor(this.coin_2 / this.pack_size_coin_2) * this.pack_size_coin_2
      if (this.coin_1) this.coin_1 = Math.floor(this.coin_1 / this.pack_size_coin_1) * this.pack_size_coin_1
      this.amountChange()

      this.split_message = "Ok that's done! Please check the new values before proceeding."
    },
    submit() {
      this.submit_loading = true

      this.amountChange()

      this.note_50_error = false
      this.note_20_error = false
      this.note_10_error = false
      this.note_5_error = false
      this.coin_200_error = false
      this.coin_100_error = false
      this.coin_50_error = false
      this.coin_20_error = false
      this.coin_10_error = false
      this.coin_5_error = false
      this.coin_2_error = false
      this.coin_1_error = false

      this.split_message = ""
      this.split_error = false
      this.error_message = ""

      var order = {
        order_reference: this.order_reference,
        opening: this.opening,
        note_50: this.note_50,
        note_20: this.note_20,
        note_10: this.note_10,
        note_5: this.note_5,
        coin_200: this.coin_200,
        coin_100: this.coin_100,
        coin_50: this.coin_50,
        coin_20: this.coin_20,
        coin_10: this.coin_10,
        coin_5: this.coin_5,
        coin_2: this.coin_2,
        coin_1: this.coin_1,
        delivery_date: this.delivery_date,
        standing_order: this.standing_order,
        standing_order_frequency: this.standing_order_frequency,
        standing_order_end_date_indefinitely: this.standing_order_end_date_indefinitely,
        standing_order_end_date: this.standing_order_end_date
      }

      if (!order.order_reference) {
        this.error_message = "Please enter your name"
        this.submit_loading = false
        return
      }

      if (this.amount <= 0) {
        this.error_message = "No denomination values have been set"
        this.submit_loading = false
        return
      }

      if (!order.delivery_date) {
        this.error_message = "Delivery date not selected"
        this.submit_loading = false
        return
      }

      var delivery_date = new Date(order.delivery_date)
      if (isNaN(delivery_date.getTime())) {
        this.error_message = "Invalid date"
        this.submit_loading = false
        return
      }

      var YYYYMMDD = [
        delivery_date.getFullYear(),
        (delivery_date.getMonth() + 1).toString().padStart(2, "0"),
        delivery_date.getDate().toString().padStart(2, "0")
      ].join("-")
      if (this.non_delivery_dates.filter(d => d.non_delivery_date == YYYYMMDD).length) {
        this.error_message = "Non delivery date"
        this.submit_loading = false
        return
      }

      // Calculate amount, check against credit limit
      var total = 0
      if (order.note_50) total += order.note_50
      if (order.note_20) total += order.note_20
      if (order.note_10) total += order.note_10
      if (order.note_5) total += order.note_5
      if (order.coin_200) total += order.coin_200
      if (order.coin_100) total += order.coin_100
      if (order.coin_50) total += order.coin_50
      if (order.coin_20) total += order.coin_20
      if (order.coin_10) total += order.coin_10
      if (order.coin_5) total += order.coin_5
      if (order.coin_2) total += order.coin_2
      if (order.coin_1) total += order.coin_1

      if (total > this.customer.credit_limit) {
        this.error_message = "Credit limit exceeded"
        this.submit_loading = false
        return
      }

      // Delivery date must be beyond customer lead time and holiday period
      if (delivery_date < this.$store.getters.minOrderDate(0)) {
        this.error_message = "Delivery date before lead time"
        this.submit_loading = false
        return
      }

      // Customer coin/note/split enabled
      var hasNotes = !!(order.note_50 || order.note_20 || order.note_10 || order.note_5)
      if (!this.customer.note_enabled)
        if (hasNotes) {
          this.error_message = "Notes not allowed"
          this.submit_loading = false
          return
        }

      var hasCoins = !!(
        order.coin_200 ||
        order.coin_100 ||
        order.coin_50 ||
        order.coin_20 ||
        order.coin_10 ||
        order.coin_5 ||
        order.coin_2 ||
        order.coin_1
      )
      if (!this.customer.coin_enabled)
        if (hasCoins) {
          this.error_message = "Coins not allowed"
          this.submit_loading = false
          return
        }

      this.note_50_error = !!(order.note_50 % this.pack_size_note_50)
      this.note_20_error = !!(order.note_20 % this.pack_size_note_20)
      this.note_10_error = !!(order.note_10 % this.pack_size_note_10)
      this.note_5_error = !!(order.note_5 % this.pack_size_note_5)
      this.coin_200_error = !!(order.coin_200 % this.pack_size_coin_200)
      this.coin_100_error = !!(order.coin_100 % this.pack_size_coin_100)
      this.coin_50_error = !!(order.coin_50 % this.pack_size_coin_50)
      this.coin_20_error = !!(order.coin_20 % this.pack_size_coin_20)
      this.coin_10_error = !!(order.coin_10 % this.pack_size_coin_10)
      this.coin_5_error = !!(order.coin_5 % this.pack_size_coin_5)
      this.coin_2_error = !!(order.coin_2 % this.pack_size_coin_2)
      this.coin_1_error = !!(order.coin_1 % this.pack_size_coin_1)

      var hasSplits =
        this.note_50_error ||
        this.note_20_error ||
        this.note_10_error ||
        this.note_5_error ||
        this.coin_200_error ||
        this.coin_100_error ||
        this.coin_50_error ||
        this.coin_20_error ||
        this.coin_10_error ||
        this.coin_5_error ||
        this.coin_2_error ||
        this.coin_1_error

      if (!this.customer.split_enabled)
        if (hasSplits) {
          this.split_error = true
          this.error_message = "Split packs not allowed"
          this.submit_loading = false
          return
        }

      // Customer coin/note/split days
      var delivery_day = delivery_date.getDay()

      var coin_day_error = ""
      if (hasCoins && !this.customer.coin_all) {
        switch (delivery_day) {
          case 0:
            if (!this.customer.coin_sun) coin_day_error = "Coins can't be delivered on Sundays"
            break
          case 1:
            if (!this.customer.coin_mon) coin_day_error = "Coins can't be delivered on Mondays"
            break
          case 2:
            if (!this.customer.coin_tue) coin_day_error = "Coins can't be delivered on Tuesdays"
            break
          case 3:
            if (!this.customer.coin_wed) coin_day_error = "Coins can't be delivered on Wednesdays"
            break
          case 4:
            if (!this.customer.coin_thu) coin_day_error = "Coins can't be delivered on Thursdays"
            break
          case 5:
            if (!this.customer.coin_fri) coin_day_error = "Coins can't be delivered on Fridays"
            break
          case 6:
            if (!this.customer.coin_sat) coin_day_error = "Coins can't be delivered on Saturdays"
        }
      }
      if (coin_day_error) {
        this.error_message = coin_day_error
        this.submit_loading = false
        return
      }
      var note_day_error = ""
      if (hasNotes && !this.customer.note_all) {
        switch (delivery_day) {
          case 0:
            if (!this.customer.note_sun) note_day_error = "Notes can't be delivered on Sundays"
            break
          case 1:
            if (!this.customer.note_mon) note_day_error = "Notes can't be delivered on Mondays"
            break
          case 2:
            if (!this.customer.note_tue) note_day_error = "Notes can't be delivered on Tuesdays"
            break
          case 3:
            if (!this.customer.note_wed) note_day_error = "Notes can't be delivered on Wednesdays"
            break
          case 4:
            if (!this.customer.note_thu) note_day_error = "Notes can't be delivered on Thursdays"
            break
          case 5:
            if (!this.customer.note_fri) note_day_error = "Notes can't be delivered on Fridays"
            break
          case 6:
            if (!this.customer.note_sat) note_day_error = "Notes can't be delivered on Saturdays"
        }
      }
      if (note_day_error) {
        this.error_message = note_day_error
        this.submit_loading = false
        return
      }

      var split_day_error = ""
      if (hasSplits && !this.customer.split_all) {
        switch (delivery_day) {
          case 0:
            if (!this.customer.split_sun) split_day_error = "Split packs can't be delivered on Sundays"
            break
          case 1:
            if (!this.customer.split_mon) split_day_error = "Split packs can't be delivered on Mondays"
            break
          case 2:
            if (!this.customer.split_tue) split_day_error = "Split packs can't be delivered on Tuesdays"
            break
          case 3:
            if (!this.customer.split_wed) split_day_error = "Split packs can't be delivered on Wednesdays"
            break
          case 4:
            if (!this.customer.split_thu) split_day_error = "Split packs can't be delivered on Thursdays"
            break
          case 5:
            if (!this.customer.split_fri) split_day_error = "Split packs can't be delivered on Fridays"
            break
          case 6:
            if (!this.customer.split_sat) split_day_error = "Split packs can't be delivered on Saturdays"
        }
      }
      if (split_day_error) {
        this.split_error = true
        this.error_message = split_day_error
        this.submit_loading = false
        return
      }

      // Minimum amount
      if (hasSplits) {
        var adjusted = false

        if (order.note_50)
          if (order.note_50 % this.minimum_note_50) {
            this.note_50 += this.minimum_note_50 - (order.note_50 % this.minimum_note_50)
            adjusted = true
          }
        if (order.note_20)
          if (order.note_20 % this.minimum_note_20) {
            this.note_20 += this.minimum_note_20 - (order.note_20 % this.minimum_note_20)
            adjusted = true
          }
        if (order.note_10)
          if (order.note_10 % this.minimum_note_10) {
            this.note_10 += this.minimum_note_10 - (order.note_10 % this.minimum_note_10)
            adjusted = true
          }
        if (order.note_5)
          if (order.note_5 % this.minimum_note_5) {
            this.note_5 += this.minimum_note_5 - (order.note_5 % this.minimum_note_5)
            adjusted = true
          }
        if (order.coin_200)
          if (order.coin_200 % this.minimum_coin_200) {
            this.coin_200 += this.minimum_coin_200 - (order.coin_200 % this.minimum_coin_200)
            adjusted = true
          }
        if (order.coin_100)
          if (order.coin_100 % this.minimum_coin_100) {
            this.coin_100 += this.minimum_coin_100 - (order.coin_100 % this.minimum_coin_100)
            adjusted = true
          }
        if (order.coin_50)
          if ((order.coin_50 * 100) % (this.minimum_coin_50 * 100)) {
            this.coin_50 += this.minimum_coin_50 - (order.coin_50 % this.minimum_coin_50)
            adjusted = true
          }
        if (order.coin_20)
          if ((order.coin_20 * 100) % (this.minimum_coin_20 * 100)) {
            this.coin_20 += this.minimum_coin_20 - (order.coin_20 % this.minimum_coin_20)
            adjusted = true
          }
        if (order.coin_10)
          if ((order.coin_10 * 100) % (this.minimum_coin_10 * 100)) {
            this.coin_10 += this.minimum_coin_10 - (order.coin_10 % this.minimum_coin_10)
            adjusted = true
          }
        if (order.coin_5)
          if ((order.coin_5 * 100) % (this.minimum_coin_5 * 100)) {
            this.coin_5 += this.minimum_coin_5 - (order.coin_5 % this.minimum_coin_5)
            adjusted = true
          }
        if (order.coin_2)
          if ((order.coin_2 * 100) % (this.minimum_coin_2 * 100)) {
            this.coin_2 += this.minimum_coin_2 - (order.coin_2 % this.minimum_coin_2)
            adjusted = true
          }
        if (order.coin_1)
          if ((order.coin_1 * 100) % (this.minimum_coin_1 * 100)) {
            this.coin_1 += this.minimum_coin_1 - (order.coin_1 % this.minimum_coin_1)
            adjusted = true
          }

        if (adjusted) {
          this.amountChange()

          this.split_message =
            "We have automatically adjusted the values up to the minimum amounts. Please check the new values before proceeding."

          this.submit_loading = false
          return
        }
      }

      // Denominations must be correctly divisible
      var denomination_error = false
      if (order.note_50) if (order.note_50 % 50) denomination_error = true
      if (order.note_20) if (order.note_20 % 20) denomination_error = true
      if (order.note_10) if (order.note_10 % 10) denomination_error = true
      if (order.note_5) if (order.note_5 % 5) denomination_error = true
      if (order.coin_200) if (order.coin_200 % 2) denomination_error = true
      if (order.coin_100) if (order.coin_100 % 1) denomination_error = true
      if (order.coin_50) if ((order.coin_50 * 100) % 50) denomination_error = true
      if (order.coin_20) if ((order.coin_20 * 100) % 20) denomination_error = true
      if (order.coin_10) if ((order.coin_10 * 100) % 10) denomination_error = true
      if (order.coin_5) if ((order.coin_5 * 100) % 5) denomination_error = true
      if (order.coin_2) if ((order.coin_2 * 100) % 2) denomination_error = true
      if (order.coin_1) if ((order.coin_1 * 100) % 1) denomination_error = true
      if (denomination_error) {
        this.error_message =
          "Each denomination must be ordered in an amount divisible by itself. For example, £100 in £20 notes is possible, £110 in £20 notes is not. Please adjust your amounts accordingly, thank you!"
        this.submit_loading = false
        return
      }

      if (this.standing_order) {
        if (!this.standing_order_frequency) {
          this.error_message = "Please choose the standing order frequency"
          this.submit_loading = false
          return
        }

        var standing_order_end_date = new Date(this.standing_order_end_date)
        if (isNaN(standing_order_end_date.getTime())) {
          this.error_message = "Invalid end date"
          this.submit_loading = false
          return
        }

        if (standing_order_end_date < delivery_date) {
          this.error_message = "Standing order end date before delivery date"
          this.submit_loading = false
          return
        }
      }

      if (this.editingStandingOrder) {
        var standing_order_end_date = new Date(this.standing_order_end_date)
        if (isNaN(standing_order_end_date.getTime())) {
          this.error_message = "Invalid end date"
          this.submit_loading = false
          return
        }

        if (standing_order_end_date < delivery_date) {
          this.error_message = "Standing order end date before delivery date"
          this.submit_loading = false
          return
        }
      }

      this.submit_loading = true

      if (this.$route.params.id) order.id = this.$route.params.id
      if (this.editingStandingOrder) {
        order.standing_order = false
        order.editing_standing_order = true
      }

      api
        .request("post", "order", { order })
        .then(() => {
          api
            .request("get", "customer?type=standing-order-status&account_number=" + this.customer.account_number)
            .then(standingOrderStatus => {
              this.$store.commit("setStandingOrderEndingSoon", standingOrderStatus)
            })

          if (this.editingStandingOrder) {
            this.success_order = "Thank you, your standing order has been edited"
          } else if (this.$route.params.id) {
            this.success_order = "Thank you, your order has been edited"
          } else if (order.standing_order) {
            this.success_order = "Thank you, your standing order has now been created"
          } else this.success_order = "Thank you, your order has now been placed."
          this.success = true
          this.submit_loading = false
          window.scrollTo(0, 0)
        })
        .catch(err => {
          if (err.response && err.response.status == 400) {
            this.error_message = err.response.data
          } else {
            this.error_message = "There has been an error, please try again"
          }
          this.submit_loading = false
        })
    },
    clearAll() {
      this.split_message = ""
      this.split_error = false
      this.error_message = ""
      this.submit_loading = false

      this.order_reference = ""
      this.opening = ""
      this.note_50 = ""
      this.note_20 = ""
      this.note_10 = ""
      this.note_5 = ""
      this.coin_200 = ""
      this.coin_100 = ""
      this.coin_50 = ""
      this.coin_20 = ""
      this.coin_10 = ""
      this.coin_5 = ""
      this.coin_2 = ""
      this.coin_1 = ""
      this.amount = 0
      this.delivery_date = ""
      this.standing_order = ""
      this.standing_order_frequency = ""
      this.standing_order_end_date = ""
      this.standing_order_end_date_indefinitely = ""
    },
    amountChange() {
      if (this.note_50 < 0) this.note_50 = 0
      if (this.note_20 < 0) this.note_20 = 0
      if (this.note_10 < 0) this.note_10 = 0
      if (this.note_5 < 0) this.note_5 = 0
      if (this.coin_200 < 0) this.coin_200 = 0
      if (this.coin_100 < 0) this.coin_100 = 0
      if (this.coin_50 < 0) this.coin_50 = 0
      if (this.coin_20 < 0) this.coin_20 = 0
      if (this.coin_10 < 0) this.coin_10 = 0
      if (this.coin_5 < 0) this.coin_5 = 0
      if (this.coin_2 < 0) this.coin_2 = 0
      if (this.coin_1 < 0) this.coin_1 = 0

      var total = 0
      if (this.note_50) total += this.note_50
      if (this.note_20) total += this.note_20
      if (this.note_10) total += this.note_10
      if (this.note_5) total += this.note_5
      if (this.coin_200) total += this.coin_200
      if (this.coin_100) total += this.coin_100
      if (this.coin_50) total += this.coin_50
      if (this.coin_20) total += this.coin_20
      if (this.coin_10) total += this.coin_10
      if (this.coin_5) total += this.coin_5
      if (this.coin_2) total += this.coin_2
      if (this.coin_1) total += this.coin_1

      this.amount = total
      this.creditLimit = this.customer.credit_limit - this.amount
    },
    toggle(target) {
      var parent = document.getElementById(target)
      var panel = parent.querySelector(".order-section-body")
      if (parent.classList.contains("active")) {
        panel.style.maxHeight = null
        parent.classList.remove("active")
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px"
        parent.classList.add("active")
      }
    },
    toggleOpen(target) {
      if (this.order_reference || this.$route.params.id) {
        var parent = document.getElementById(target)
        var panel = parent.querySelector(".order-section-body")

        panel.style.maxHeight = panel.scrollHeight + "px"
        parent.classList.add("active")
      }
    }
  },
  mounted() {
    if (this.$store.state.customer.first_visit || this.$route.query.showtour) {
      setTimeout(() => {
        var steps = [
          {
            element: document.querySelector(".step7"),
            intro: this.stepText[0]
          },
          {
            element: document.querySelector(".step8"),
            intro: this.stepText[1]
          },
          {
            element: document.querySelector(".step9"),
            intro: this.stepText[2]
          },
          {
            element: document.querySelector(".step10"),
            intro: this.stepText[3]
          },
          {
            element: document.querySelector(".step11"),
            intro: this.stepText[4]
          },
          {
            element: document.querySelector(".step12"),
            intro: this.stepText[5]
          }
        ]

        if (window.innerWidth < 960) {
          steps = [
            {
              element: document.querySelector(".step7"),
              intro: this.stepText[0]
            },
            {
              element: document.querySelector(".step8"),
              intro: this.stepText[1]
            },
            {
              element: document.querySelector(".step9"),
              intro: this.stepText[2]
            },
            {
              element: document.querySelector(".step10"),
              intro: this.stepText[3]
            },
            {
              element: document.querySelector(".step11"),
              intro: this.stepText[4]
            }
          ]
        }

        this.$intro()
          .setOptions({
            steps,
            nextLabel: "Next &rarr;",
            prevLabel: "&larr; Back",
            doneLabel: "Next &rarr;",
            exitOnOverlayClick: false
          })
          .start()
          .oncomplete(() => {
            window.location.replace("/home?tour=finished&showtour=true")
          })
      }, 750)
    }

    const flatPickerWrapperElement = this.$refs.flatPickerWrapper.getElem().nextElementSibling
    flatPickerWrapperElement.setAttribute("aria-label", "Delivery Date")
  },
  watch: {
    standing_order_end_date_indefinitely: function () {
      this.standing_order_end_date = ""
    },
    standing_order_end_date: function () {
      this.standing_order_end_date_indefinitely = ""
    },
    order_reference: function () {
      if (!this.order_reference) {
        const totalSections = 5

        for (let i = 1; i <= totalSections; i++) {
          const parent = document.getElementById(`section${i}`)

          if (parent) {
            const panel = parent.querySelector(".order-section-body")

            if (panel) {
              panel.style.maxHeight = null
            }

            parent.classList.remove("active")
          }
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.order-section {
  transition: all 0.2s;
  margin-top: 2rem;
}

.active .order-section-body {
  max-height: none;
}

.order-section-header {
  cursor: pointer;
  background: #f3f3f3;
  padding: 1.5rem 2rem;
  font-size: 2.5rem;
  transition: all 0.2s;
}

.subheading {
  color: #ca0028;
  padding: 1.5rem 0;
  font-size: 2.5rem;
}

.active .order-section-header {
  background: #fff;
  color: #ca0028;
}

.active {
  box-shadow: 0px 0px 13px -2px #d3d3d3;
}

.order-section-body {
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s;
  border-bottom: 0rem solid transparent;
  width: 100%;
  opacity: 0;
}

.active .order-section-body {
  border-bottom: 0.5rem solid #ca0028;
  padding: 3rem 2rem;
  padding-top: 1rem;
  display: table;
  opacity: 1;
}

.btn-round {
  background: white;
  padding: 0.25rem 1rem;
  margin: 0 0.5rem;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 1px solid white;
  border-radius: 1rem;
  color: #333;
}

.info-content p {
  margin: 0.5rem 0;
}

.halved {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.expand {
  float: right;
  height: 2rem;
  position: relative;
  top: 0.5rem;
  transition: all 0.4s;
}

.active .expand {
  transform: rotate(270deg);
}

.radio {
  display: inline-block;
  margin: 0.5rem 0;
  margin-right: 3rem;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 2.8rem;
  cursor: pointer;
  line-height: 2rem;
  display: inline-block;
  font-size: 2.5rem;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1.8rem;
  height: 1.8rem;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 1.2rem;
  height: 1.2rem;
  background: #ca0028;
  position: absolute;
  top: 0.3rem;
  left: 0.3rem;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

[type="radio"]:focus-visible + label:before {
  outline: 2px solid #cb4e0b !important;
  outline-offset: 2px;
}

.txterror {
  color: #ca0028;
  border: 1px solid #ca0028;
}

.table-qty {
  float: left;
  font-weight: bold;
  margin-right: 1rem;
}

.table-qty td {
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.table-qty td:first-child {
  position: relative;
  top: 0.2rem;
}

.table-qty tr:last-child td {
  padding-bottom: 0;
}

.qty {
  width: 15rem;
}

.prefix {
  display: inline-block;
  margin-left: 1rem;
  margin-right: 0.5rem;
  opacity: 0.4;
}

.or,
.end_date {
  display: inline-block;
}

.or {
  color: #cccccc;
  text-decoration: underline;
  margin-right: 3rem;
  font-size: 2.5rem;
  font-weight: bold;
}

.order-total {
  clear: both;
  font-weight: bold;
  padding-top: 2.5rem;
}

.order-total span {
  color: #ca0028;
}

@media screen and (max-width: 950px) {
  .halved {
    grid-template-columns: 1fr;
  }

  .table-qty td:first-child {
    top: 0.1rem;
  }
}

@media screen and (max-width: 600px) {
  .qty {
    width: 9rem;
  }

  .table-qty:last-child {
    margin-right: 0;
  }

  .actions button {
    font-size: 1.8rem;
  }

  .actions button img {
    height: 1.5rem;
    margin-bottom: 0;
  }
}

.disabled {
  opacity: 0.5;
  /* Greyed out appearance */
  pointer-events: none;
  /* Disables clicking on any element inside */
}
</style>
